// Config file
import MjmlToDndConditions from './dnd-style-engine.config.json'
import textDefaultStyle from '@/assets/config/default-style/text.conf'
import { workSpaceMode } from '@/services/states/states.service.js'

import {
  includes as _includes,
  groupBy as _groupBy,
  forEach as _forEach,
  map as _map,
  get as _get
} from 'lodash-es'

/**
 * Service declaration ------------------------------------
 */

// Func@getStyles
/**
 * Inject css style from MJML JSON to vue-smooth-dnd html user interface
 * @param  {Object}  element  (MJML element)
 * @param  {Object}  parentEl (parent MJML element)
 *
 * @return {String}          (inline CSS)
 */
function getStyles (element, parentEl) {
  let styles = ''

  if (!element.attributes) return styles
  _forEach(Object.keys(element.attributes), key => {
    const tagConditions = _get(MjmlToDndConditions, element.tagName)
    const excludeList = _get(tagConditions, 'exclude', [])
    const parentElConditions = _get(tagConditions, 'parentEl', [])

    const desktopExclusive = _get(tagConditions, 'desktopExclusive', [])
    const mobileExclusive = _get(tagConditions, 'mobileExclusive', [])
    const mode = workSpaceMode().get()

    const desktopKey = _get(desktopExclusive, key)
    const mobileKey = _get(mobileExclusive, key)
    const isRestricted = ((desktopKey && mode === 'mobile') || (mobileKey && mode === 'desktop')) || false

    const cssKey = _get(tagConditions, `stylesSyntax.${[key]}`) || _get(MjmlToDndConditions['*'], `stylesSyntax.${[key]}`) || key
    const parentElFail = (Boolean(parentEl) && !_includes(parentElConditions, key)) || _includes(excludeList, key)
    const elFail = !parentEl && _includes(parentElConditions, key)

    if (parentElFail || elFail || isRestricted || element.attributes[key] === null) return
    if (Boolean(parentEl) && parentEl.attributes['background-color']) {
      styles += `background-color: ${parentEl.attributes['background-color']}; `
    }

    if (element.attributes[key] != "") {
      styles += key === 'background-url'
        ? `${cssKey}: url('${element.attributes[key]}'); background-position: center top; `
        : `${cssKey}: ${element.attributes[key]}; `
    }
  })

  if (!styles.length) return

  return styles
}
// Func@getStyles

// Func@getDefaultStyles
/**
 * Get defaults styles
 * @return {Function}
 */
function getDefaultStyles () {
  return {
    /**
     * Return links
     * @return {String} (Css properties)
     */
    links () {
      const properties = _groupBy(textDefaultStyle.links.options, 'property.name')
      let style = `color: ${textDefaultStyle.links.color};`

      for (const property in properties) {
        const activeValues = properties[property].filter(value => value.active)
        if (property === 'text-decoration' && !activeValues.length) {
          style += ' text-decoration: none;'
          continue
        } else if (property !== 'text-decoration' && !activeValues.length) continue

        style = `${style} ${property}: ${_map(activeValues, 'property.value').join(' ')};`
      }

      return style
    }
  }
}
// Func@getDefaultStyles

// Func@setDefaultStyles
/**
 * Set defaults styles for DND
 * @return {Function}
 */
function setDefaultStyles () {
  return {
    /**
     * Set links style
     */
    links (propertiesString) {
      const properties = propertiesString.split('; ').filter(item => item)
      properties.forEach(property => {
        const options = textDefaultStyle.links.options
        const propArray = property.split(': ')
        const name = _get(propArray, '0', '').trim()
        const values = _get(propArray, '1', '').split(' ')

        if (name === 'color') {
          textDefaultStyle.links.color = values.shift()
          return
        }

        const opt = options.find(opt => values.includes(opt.property.value))
        if (opt) opt.active = true
      })
    }
  }
}
// Func@setDefaultStyles

export {
  getStyles,
  getDefaultStyles,
  setDefaultStyles
}
