export default {
  init: true,
  loading: false,
  visibleDeleteDialog: false,
  selectedBlock: null,
  typingTimer: null,
  doneTypingInterval: 300,
  total: 0,
  currentPage: 1,
  limit: 12,
  orderBy: '-created_at',
  loadingScroll: false,
  noMore: false,
  searchInput: '',
  blocks: [],
  maxHeight: 0,
  maxScroll: 0
}
