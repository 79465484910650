// Component states
import states from './workspace.state.js'
import libraryRows from '@/components/standalone/structure-library/structure-library.state.js'

// Components
import { Container, Draggable } from 'vue-smooth-dnd'
import MdiDelete from 'vue-material-design-icons/TrashCanOutline.vue'
import TemplateStructure from '@/components/standalone/template-structure/Template-structure.vue'

// Css variable
import { templateMinWidth, templateBottomSpace } from '@/assets/styles/variables.scss'

// Default style conf
import textDefaultStyle from '@/assets/config/default-style/text.conf'
import componantsDefaultStyle from '@/assets/config/default-style/componants.conf'

// Services
import {
  globalstates,
  setOverview,
  mailPreview,
  mailTitle,
  mailLanguage,
  openOpenGraphPanel,
  closeEditPanel,
  readingDirection,
  currentGabaritId,
  setDefaultStyle,
  buildDefaultStylesObject,
  devmode,
  getVersionId,
  getHtmlBlocksVersions,
  setHtmlBlocksVersions,
  outdatedBlocks,
  resetOutdatedBlocks
} from '@/services/states/states.service'
import { getStyles } from '@/services/dnd-style-engine/dnd-style-engine.service'
import { debounce, eventEmit } from '@/services/utils/utils.service'
import { parseTemplate, generateSavedBlockStructure } from '@/services/dnd-template/dnd-template.service'
// Config file
import shortcuts from '@/assets/config/shortcuts/shortcuts.conf'
import mjBodyConf from '@/assets/config/mjml-components/mj-body.conf.json'

// Libraries
import {
  cloneDeep as _cloneDeep,
  isEqual as _isEqual,
  groupBy as _groupBy,
  remove as _remove,
  uniqBy as _uniqBy,
  map as _map,
  get as _get,
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Workspace'

// Vue@props
const props = {
  mode: String,
  displayRendering: Boolean
}
// Vue@props

// Vue@data
const data = function () {
  return {
    states,
    shortcuts,
    workspaceStates: _get(globalstates, 'workspace'),
    editPanelState: _get(globalstates, 'leftPanel.editPanel', {}),
    opengraphPanelState: _get(globalstates, 'leftPanel.openGraphPanel', {}),
    conf: {
      textDefaultStyle,
      componantsDefaultStyle
    }
  }
}
// Vue@data

// Vue@subComponents
const components = {
  TemplateStructure,
  Container,
  Draggable,
  MdiDelete
}
// Vue@subComponents

// Methods
const methods = {

  // Func@redo
  /**
   * Redo previous undo
   */
  redo () {
    const redo = this.workspaceStates.history.getRedo()
    if (!redo.exist) return
    this.states.fromHistory = true
    this.templateBody.children.length = 0
    this.templateBody.children.push(...redo.value)
  },
  // Func@redo

  // Func@undo
  /**
   * Undo previous action
   */
  undo () {
    const undo = this.workspaceStates.history.getUndo()
    if (!undo.exist) return
    this.states.fromHistory = true
    this.templateBody.children.length = 0
    this.templateBody.children.push(...undo.value)
  },
  // Func@undo

  // Func@addRowOnEnter
  /**
   * Add new structure to workspace on press enter
   * (this will work only if seach field is focused)
   */
  addRowOnEnter (columnsNumber) {
    if (!columnsNumber) return

    const rows = _get(globalstates, 'workspace.rows', [])
    const groupedRows = _groupBy(libraryRows.items, 'data.children.length')
    const rowsInContext = groupedRows[columnsNumber]
    const rowRequested = rowsInContext.find(row => {
      return row.name.split(' ').every((val, i, arr) => val === arr[0])
    })

    const row = _cloneDeep(rowRequested.data)
    const rowsBgColor = this.conf.componantsDefaultStyle.structures.rows['background-color']
    if (rowsBgColor) row.attributes['background-color'] = rowsBgColor

    row.children.map(column => {
      const columnsBgColor = this.conf.componantsDefaultStyle.structures.columns['background-color']
      if (columnsBgColor) column.attributes['background-color'] = columnsBgColor
      return column
    })

    rows.push(row)
  },
  // Func@addRowOnEnter

  // Func@generateBinds
  /**
   * [generateBinds description]
   * @param  {[type]} shortcut    [description]
   * @param  {[type]} dynamicBind [description]
   * @return {[type]}             [description]
   */
  generateBinds (shortcut, dynamicBind) {
    return _map(shortcut.binds, bind => bind === shortcut.var ? dynamicBind.toString() : bind)
  },
  // Func@generateBinds

  // Func@switchZoom
  /**
   * Switch view type and autoscroll
   * to center template on Unzoomed mode
   */
  switchZoom () {
    this.states.resize = !this.states.resize
    setTimeout(() => {
      let top
      const elem = this.$refs.workspace
      const elemHeight = elem.offsetHeight
      const windowHeight = window.innerHeight

      switch (this.states.resize) {
        case true:
          top = elemHeight > windowHeight
            ? ((elemHeight - (templateBottomSpace / 2)) / 2) - (windowHeight / 2)
            : (windowHeight / 2) - ((elemHeight - (templateBottomSpace / 2)) / 2)
          break
        case false:
          top = 0
          break
      }

      elem.parentNode.scroll({
        top,
        left: 0,
        behavior: 'smooth'
      })
    }, 250)
  },
  // Func@switchZoom

  // Func@saveGabarit
  /**
   * Save new gabarit or overwrite an existing one
   * @param  {String} method (put or patch)
   */
  async saveGabarit (method) {
    if (!this.hasJwtToken) {
      console.warn('No jwt Token: Save request is disabled')
      return
    }
    const id = this.states.selectedGabaritId || null
    const template = this.states.template

    try {
      await this.api.templates(id)[method]({
        name: this.states.gabaritName,
        mjml: template
      })
    } catch (err) {
      console.error(err)
    }
  },
  // Func@saveGabarit

  // Func@getStyle
  /**
   * Get structure MJML style
   * @param  {Object} content    (MJML object)
   * @param  {String} parentData (Root level)
   *
   * @return {String}         (CSS style)
   */
  getStyles (element, parentData) {
    const el = _cloneDeep(element)
    const isBody = el.tagName === 'mj-body'
    const isNotAutoWidth = el.attributes.width !== '100%'

    // ex: For 640px width, we need 644px because of green dashed border (2px*2)
    // see https://gitlab.1000mercis.com/fs_qa/html_editor-qa/issues/245
    if (isBody && isNotAutoWidth) {
      el.attributes.width = `${parseInt(el.attributes.width) + 4}px`
    }

    return getStyles(el, parentData)
  },
  // Func@getStyle

  // Func@updateOnResize
  /**
   * Update state isMobile depending screen size
   * @set {Boolean} (isMobile)
   */
  updateOnResize () {
    this.$set(this.states, 'isMobile', window.innerWidth < (templateMinWidth | this.states.mobileSizeFallBack))
  },
  // Func@updateOnResize

  // Func@loadTemplate
  /**
   * Fetch last updated html from database
   * when gabaritId is not set, else it will load
   * the gabarit with the given Id
   */
  async loadTemplate () {
    const gabaritId = this.workspaceStates.gabaritId
    const template = gabaritId
      ? await this.api.templates(gabaritId).load()
      : await this.api
        .mjml(this.$route.params.id)
        .load()

    currentGabaritId().set(gabaritId)
    if (template && getVersionId()) {
      this.states.template = Object.assign(this.states.template, template)
      const blocks = await this.api.mjml(getVersionId()).loadBlocksVersions()
      if (blocks && blocks.length > 0 && !gabaritId) {
        this.states.loadingBlocks = true
        this.initBlocks()
        eventEmit('show-saved-block')
      } else {
        setHtmlBlocksVersions([])
        eventEmit('no-used-saved-block')
        this.$set(this.states, 'intialized', true)
      }
    } else {
      this.states.template = Object.assign(this.states.template, template)
      setHtmlBlocksVersions([])
      eventEmit('no-used-saved-block')
      this.$set(this.states, 'intialized', true)
    }

    this.workspaceStates.history.init(this.templateBody.children)

    eventEmit('default-style', buildDefaultStylesObject())
  },
  // Func@loadTemplate

  async initBlocks() {
    const basicMjml =  {
      tagName: 'mjml',
      attributes: {},
      children: [
        {
          tagName: 'mj-body',
          attributes: _get(mjBodyConf, 'attributes.default', {}),
          children: []
        },
        {
          tagName: 'mj-head',
          attributes: {},
          children: []
        }
      ]
    }
    this.$set(this.states, 'intialized', true)
    const blocks = getHtmlBlocksVersions()
    let count = 0
    let blockPromise = []
    let blockVersionsPromise = []

    // List of promise to get each block used version in content (can be outdated)
    blockVersionsPromise = blocks.map(block => this.api.blocks().getVersion(block.blocks_versions_id))
    Promise.all(blockVersionsPromise)
    .then((blockVersions) => {
      // List of promise to get block MJML from the block's version
      blockPromise = blockVersions.map(blockVersion => this.api.blocks(blockVersion.block_id).loadBlock())
      Promise.all(blockPromise)
      .then((blockResponses) => {
        // For each block, we have to use the parseTemplate method to fit with DnD
        const parseTemplatePromise = blockResponses.map((blockResponse, index) => {
          basicMjml.children[0].children = JSON.parse(blockVersions[index].mjml_json)
          return this.parseTemplateVersion(basicMjml)
        })
        Promise.all(parseTemplatePromise)
        .then(templates => {
          templates.forEach((version, index) => {
            // For each block, we insert it into a single line with column to have the correct display (no hover on block content)
            const data = generateSavedBlockStructure(version.children[0].children)
            data.block = {
              unique_id: blocks[index].unique_id,
              block_id: blockVersions[index].block_id,
              id: blockVersions[index].id,
              name: blockResponses[index].name,
              index: blocks[index].children_index,
              mjml_json: version.children[0].children,
              is_last_version: blockVersions[index].version === blockResponses[index].current_version.version
            }
            // here we check if the block is inserted with the latest version to count how many are outdated
            if (blockVersions[index].version !== blockResponses[index].current_version.version) {
              count++
              outdatedBlocks().set(blocks[index].id)
              this.$emit('outdated-block')
            }
            // Last, we insert the block at its saved index
            this.templateBody.children.splice(blocks[index].children_index, 0, data)
            if (index === templates.length - 1) {
              eventEmit('loaded')
            }
          })
        })
      })
    })
    if (count === 0) {
      eventEmit('outdated-blocks-count', 0)
      resetOutdatedBlocks()
      this.$emit('hide-banner')
    }
  },

  async parseTemplateVersion (mjml) {
    return await parseTemplate({ template: mjml }).toDnd()
  },
  async loadBlock (id) {
    this.api.blocks(id).lastVersion()
    .then((response) => {
      if (response) {
        this.states.template = response.version
        eventEmit('loaded')
      }
    })
  },
  // Func@cleanObject
  cleanObject (obj) {
    for (const key in obj) delete obj[key]
  },
  // Func@cleanObject

  // Func@cleanTemplate
  /**
   * clean the current content when
   * the user wants to change to an empty page
   */
  cleanTemplate () {
    // clean mjHead
    const mjHead = this.states.template.children.filter(el => el.tagName === 'mj-head')[0]
    mjHead.children.length = 0
    this.cleanObject(mjHead.attributes)

    // clean mjBody
    const mjBody = this.states.template.children.filter(el => el.tagName === 'mj-body')[0]
    mjBody.children.length = 0
    const defaultKeys = Object.keys(this.mjBodyDefaultAttrib)
    for (const key in mjBody.attributes) {
      if (defaultKeys.includes(key)) {
        mjBody.attributes[key] = this.mjBodyDefaultAttrib[key]
      } else {
        delete mjBody.attributes[key]
      }
    }

    // clean template attributes
    this.cleanObject(this.states.template.attributes)
  },
  // Func@cleanTemplate

  // Func@saveTemplate
  async saveTemplate () {
    this.states.loadingBlocks = false
    await this.api
      .mjml(this.$route.params.id)
      .save(this.states.template)
    this.$emit('update')
  },
  // Func@saveTemplate

  // Func@updateOverview
  /**
   * Update overview and set generated html in service
   * @return {Promise}
   */
  async updateOverview () {
    if (devmode().get()) {
      const html = await this.api
        .mjml()
        .render(this.states.template)
      setOverview(html)
    }
  },
  // Func@updateOverview

  // Func@hasDeviceOnlyRows
  /**
   * Recursive method to check if at least one template's row is specific to a device
   * @return {Boolean}
   */
  hasDeviceOnlyRows (elem) {
    for (const child of _get(elem, 'children', [])) {
      if (this.hasDeviceOnlyRows(child)) {
        return true
      }
    }
    const elemClass = _get(elem, 'attributes.css-class', '')
    return elemClass.indexOf('show-on-desktop') > -1 || elemClass.indexOf('show-on-mobile') > -1
  },
  // Func@hasDeviceOnlyRows

  // Func@handleMessages
  /**
   * Handle emit messages
   */
  handleMessages (event) {
    const call = event.data.call
    if (!call || typeof event.data !== 'object') return
    switch (call) {
      case 'factorly:default-style-updated': {
        setDefaultStyle(event.data.value)
        readingDirection().set(this.conf.textDefaultStyle.text.direction)
        this.saveTemplate()
        break
      }
      case 'factorly:save-new-template': {
        this.$set(this.states, 'gabaritName', event.data.value)
        this.saveGabarit('save')
        break
      }
      case 'factorly:update-template': {
        const { name, id } = event.data.value
        this.$set(this.states, 'gabaritName', name)
        this.$set(this.states, 'selectedGabaritId', id)
        this.saveGabarit('update')
        break
      }
      case 'factorly:show-sms-link-preview': {
        openOpenGraphPanel()
        closeEditPanel()
        break
      }
      case 'factorly:undo': {
        this.undo()
        break
      }
      case 'factorly:redo': {
        this.redo()
        break
      }
      case 'factorly:preheader': {
        let preheader = event.data.value
        // For preheader, encapsulate value in span having id "hidden_preheader".
        // This is used in Sendingly for preheader positioning
        if (preheader) {
          preheader = `<span id="hidden_preheader">${event.data.value}</span>`
        }
        if (preheader === mailPreview().get()) { return }
        mailPreview().set(preheader)
        debounce({
          type: 'save',
          func: this.saveTemplate
        })
        break
      }
      case 'factorly:title': {
        let title = event.data.value
        if (title === mailTitle().get()) { return }
        mailTitle().set(title)
        debounce({
          type: 'save',
          func: this.saveTemplate
        })
        break
      }
      case 'factorly:lang': {
        let lang = event.data.value
        states.template.attributes.lang = lang
        if (lang === mailLanguage().get()) { return }
        mailLanguage().set(lang)
        debounce({
          type: 'save',
          func: this.saveTemplate
        })
        break
      }
      case 'factorly:save-block': {
        let blockId = event.data.value
        this.api.blocks(blockId).postVersion({ block: this.states.template})
        .then((response) => {
          eventEmit('saved', response.data.id)
        })
        break
      }
      case 'factorly:update-block': {
        let blockId = event.data.value
        this.api.blocks(blockId).postVersion({ block: this.states.template})
        .then((response) => {
          eventEmit('updated', response.data.id)
        })
        break
      }
      case 'factorly:update-htmls-blocks-versions': {
        const htmls_blocks_versions = event.data.value
        setHtmlBlocksVersions(htmls_blocks_versions)
        this.api.mjml(this.$route.query.id).updateHtmlsBlocksVersions()
        .then(() => {
          this.loadTemplate()
        })

      }
    }
  }
  // Func@handleMessages
}

// Computed methods
const computed = {

  // Func@hasJwtToken
  /**
   * Check if we are able to reach API
   * @return {Boolean}
   */
  hasJwtToken () {
    return Boolean(this.api.token().get())
  },
  // Func@hasJwtToken

  // Func@templateBody
  /**
   * Get template body
   * @return {Object} (mj-body)
   */
  templateBody () {
    return this.states.template.children
      .find(item => item.tagName === 'mj-body')
  },
  // Func@templateBody

  // Func@enableStatesShortcut
  /**
   * Disable displayStates on edit panel active
   * @return {Boolean}
   */
  enableStatesShortcut () {
    const editPanelActivated = this.editPanelState.active
    if (editPanelActivated) this.states.displayStates = false
    return !editPanelActivated
  },
  // Func@enableStatesShortcut`

  // Func@isMobileMode
  /**
   * Check if workspace mode is set to mobile (can be either forced
   * by a user action, or automatically set because of the window size)
   * @return {Boolean}
   */
  isMobileMode () {
    return this.states.isMobile || this.workspaceStates.mode === 'mobile'
  }
  // Func@isMobileMode
}

// Vue@watchTemplate
const watch = {
  '$route.query': {
    handler () {
      const templateId = this.$route.query['template-id']
      if (templateId && currentGabaritId().get() !== templateId) {
        if (templateId === 'blank') {
          this.cleanTemplate()
          eventEmit('template-cleaned')
        } else {
          this.loadTemplate()
        }
      }
    }
  },
  templateBody: {
    handler () {
      const templateReady = this.$route.params.id && this.states.intialized && !this.states.loadingBlocks
      const triggeredByHistory = this.states.fromHistory
      const bodyChildren = this.templateBody.children
      // Save template (debounced, 600ms)
      if (templateReady) {
        console.info('DND: auto-save')
        // don't delay update event, as parent must be immediately aware of any changes
        eventEmit('update')
        debounce({
          type: 'save',
          func: this.saveTemplate
        })
      } else {
        this.states.loadingBlocks = false
        this.$set(this.states, 'intialized', true)
      }

      // Save history (debounced, 600ms)
      if (!triggeredByHistory) {
        debounce({
          type: 'history',
          func: 'add',
          params: [bodyChildren],
          service: this.workspaceStates.history
        })
      }

      // Emit to factorly action history state
      eventEmit('actions-history', this.workspaceStates.history.iterators)

      if (bodyChildren.length === 0) {
        eventEmit('empty')
      }

      // Update MJML state (and overview)
      this.updateOverview()

      // Generate dynamic key proposition list ({{MM:XXXX}})
      generateDynamicKeywordList(this.states.template)

      // Generate inventory of contents components (mj-scratch: 0, mj-image: 3, ...)
      generateComponentInventory(this.states.template)

      // Reset 'from history' state
      this.states.fromHistory = false

      // Notify parent container if the template has "device only" rows
      eventEmit('toggle-device-settings', { enable: this.hasDeviceOnlyRows(this.templateBody) })
    },
    deep: true
  }
}
// Vue@watchTemplate

// Func@generateDynamicKeywordList
/**
 * Catch all contents component tags (mj-scratch, mj-image, ...) from template and add them
 * in globale state to a centralized contents inventory
 *
 * @param  {Object} template (MJML Template)
 */
function generateComponentInventory (template) {
  const templateString = JSON.stringify(template)
  const toIgnore = ['mj-body', 'mj-section', 'mj-column', 'mj-head']
  const dynamicComponents = templateString.match(/"tagName":"(mj)-[-!@#$%^&*():|<>a-zA-Z]+"/g)

  const dynamicComponentsCleanList = _remove(
    dynamicComponents.map(key => key.replace(/"/g, '').replace(/tagName:/g, '')),
    key => !toIgnore.includes(key)
  )

  const inventory = dynamicComponentsCleanList.reduce((acc, curr) => {
    if (typeof acc[curr] === 'undefined') acc[curr] = 1
    else acc[curr] += 1
    return acc
  }, {})

  globalstates.componentsInventory = { ...inventory }
}
// Func@generateDynamicKeywordList

// Func@generateDynamicKeywordList
/**
 * Catch all dynamics tags ({{MM:KEYWORD}}) from template and add them to a centralized
 * list of tags which could be use every where in the application
 *
 * @param  {Object} template (MJML Template)
 */
function generateDynamicKeywordList (template) {
  const templateString = JSON.stringify(template)
  const dynamicKeywordList = templateString.match(/{{(MM|mm|mM|Mm):[-!@#$%^_&*(),{.?":|<>a-zA-Z0-9]+}}/g)

  const fixedKeywords = _map(dynamicKeywordList, keyword => {
    const fixedKeyword = keyword
      .toUpperCase()
      .match(/{{MM:([-!@#$%^_&*(),.?":{}|<>a-zA-Z0-9]+)}}/)[1]
      .replace(/[-!@#$%^&*(),.?":{}|<>]/g, '')
    return {
      label: fixedKeyword,
      value: `{{MM:${fixedKeyword}}}`
    }
  })

  const uniqFixedKeywords = _uniqBy(fixedKeywords, 'value')

  if (_isEqual(uniqFixedKeywords, globalstates.dynamicKeywordList)) return
  globalstates.dynamicKeywordList = uniqFixedKeywords
}
// Func@generateDynamicKeywordList

// Func@addWindowListener on create
/**
 * Add window resize event listener and define save html
 * request to be able to flush it on destroy
 */
function addWindowListener () {
  window.addEventListener('resize', this.updateOnResize)
  window.addEventListener('message', this.handleMessages)
  // Resize at init
  this.updateOnResize()
  // Load template if existing
  if (this.$route.params.id) {
    this.mjBodyDefaultAttrib = _cloneDeep(mjBodyConf.attributes.default)
    this.loadTemplate()
  } else  if (this.$route.query['block_id']) {
    this.mjBodyDefaultAttrib = _cloneDeep(mjBodyConf.attributes.default)
    this.loadBlock(this.$route.query['block_id'])
  } else this.workspaceStates.history.init(this.templateBody.children)
}
// Func@addWindowListener

// Func@removeWindowListener on destroy
/**
 * Remove window resize event listener
 */
function removeWindowListener () {
  window.removeEventListener('resize', this.updateOnResize)
}
// Func@removeWindowListener

// Vue component syntax
export default {
  name,
  data,
  props,
  watch,
  methods,
  computed,
  created: addWindowListener,
  destroyed: removeWindowListener,
  components
}
